body.page-template-default {

	section#main.generic {
		padding: 50px 0;

		@include tablet-portrait {
			min-height: 600px;
		}

		@include desktop {
			min-height: 800px;
		}

		h1 {
			font-size: 2em;
			padding: 0 0 20px 0;
		}
	}


}

