header {
	background: $dark-blue;
	padding: 20px 0;

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}


	#header-logo {
		display: flex;
		align-items: center;

		a.usau-logo {
			max-width: 80px;
			padding: 0 20px 0 0;

			@include tablet-portrait {
				max-width: 120px;
			}
		}

		h1 {
			border-left: 1px solid rgba(255, 255, 255, 0.3);
			padding: 0 0 0 20px;
			font-size: 2em;
			letter-spacing: 0.05em;

			@include tablet-portrait {
				font-size: 3em;
			}


			a {
				line-height: 0.75em;

				span.live {
					display: block;
					font-size: 0.75em;
					color: $red;

				}

				span.ultimate {
					display: block;
					color: $white;

				}
			}
		}

	}

	nav {
		display: none;
		width: 100%;
		padding: 20px 0 0 0;

		@include tablet-portrait {
			width: auto;
			display: block !important;
		}


		a {
			font-family: $proxima-nova;
			color: $white;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0.1em;
			font-size: 0.8125em;

			transition: all 0.2s ease-in-out;
			display: block;
			text-align: center;
			padding: 20px 0;

			&:hover {
				text-decoration: underline;
			}

			@include tablet-portrait {
				margin-left: 50px;
				border-bottom: 2px solid transparent;
				padding: 0 0 5px 0;
				display: inline-block;
				text-align: left;

				&:hover {
					border-bottom: 1px solid $white;
					text-decoration: none;
				}
			}
		}
	}


	#toggle {
		width: 34px;
	    cursor: pointer;
	    padding: 8px 5px 8px 5px;
	    transition: all 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
	    margin-right: 0;

		@include tablet-portrait {
			display: none;
		}

		.patty {
		    width: 30px;
		    height: 3px;
		    background: $white;
		    transition: all 0.3s;

			&:after, &:before {
			    content: '';
			    display: block;
			    width: 30px;
			    height: 3px;
			    background: $white;
			    position: absolute;
			    z-index: 1;
			    transition: all 0.3s;
			}
			&:before {
			    top: -8px;
			}
			&:after {
			    top: 8px;
			}
		}
	}

	&.open {

		#toggle .patty {
			background: none;

			&:after {
			    top: 0;
			    transform: rotate(45deg);
			}
			&:before {
			    top: 0;
			    transform: rotate(-45deg);
			}
		}

	}

}
