/* Colors
----------------------------------*/
$white: #fff;
$off-white: #fafafa;
$light-gray: #eee;
$charcoal: #444;
$slate: #2a2a2a;
$black: #000;


$dark-blue: #19243A;
$blue: #435273;
$light-blue: #6490E9;
$red: #EA1D22;
$seafoam: #AACFD8;
$gray: #BBBBBB;





/* Typography
----------------------------------*/
@font-face {
    font-family: 'bignoodletitlingregular';
    src: url('fonts/big_noodle_titling-webfont.woff2') format('woff2'),
         url('fonts/big_noodle_titling-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$big-noodle: 'bignoodletitlingregular', sans-serif;
$bebas: "bebas-neue", sans-serif;
$proxima-nova: "proxima-nova", sans-serif;
$garamond: 'EB Garamond', serif;