html, input, textarea {
	-webkit-font-smoothing: antialiased;
}
body {
	text-rendering: optimizeLegibility;
}

body, input, textarea, button {
	color: $slate;
	font: 400 100%/1 $proxima-nova;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $bebas;
	font-weight: normal;
}

p {

}

a {
	color: $blue;
	transition: all 0.2s ease-in-out;
}

.btn {
	display: inline-block;
	background: transparent;
	color: $white;
	padding: 14px 25px 12px;
	border: 1px solid $white;
	text-transform: uppercase;
	font-size: 0.8125em;
	letter-spacing: 0.05em;
	font-weight: 600;

	&:hover {
		background: $white;
		color: $red;
	}
}