body {
	background: #fcfcfc;
}

.wrapper {
	@include wrapper-padding;

}

.cover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

section#header {
	padding: 50px 0;
	text-align: center;

	h1 {
		font-family: $big-noodle;
		font-size: 2.5em;
		letter-spacing: 0.1em;
		color: $dark-blue;

		@include tablet-portrait {
			font-size: 3.5em;
		}
	}
}