body.post-type-archive-athletes {

	section#list {
		.wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		article {
			width: 100%;
			padding: 0 0 50px 0;

			@include mobile-landscape {
				width: 48.5%;
			}

			@include tablet-landscape {
				width: 32%;
				padding: 0 0 100px 0;
			}

			.info {
				padding: 20px 0 0 0;
				text-align: center;

				h3 {
					font-family: $big-noodle;
					font-size: 1.75em;
					letter-spacing: 0.1em;

					a {
						color: $dark-blue;
					}
				}
			}
		}



	}




}