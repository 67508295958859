body.single-athletes {

	
	section#hero {
		@include aspect-ratio(16, 9);
		max-height: 1080px;
		border: 10px solid $white;

		.wrapper {
			display: flex;
			height: 100%;
			align-items: flex-end;

			@include tablet-portrait {
				align-items: flex-start;
			}

			.info {
				margin: 0 0 20px 0;

				@include tablet-portrait {
					margin: 20px 0 0 0;
				}
			}
		}

		h1 {
			font-family: $big-noodle;
			font-size: 2em;
			background: $white;
			display: inline-block;
			padding: 10px 10px 0 10px;
			line-height: 1em;
			color: $blue;

			@include tablet-portrait {
				font-size: 3.5em;
				padding: 20px 20px 0 20px;
			}
		}
	}

	section#profile {
		padding: 20px 0 50px 0;

		@include tablet-portrait {
			padding: 40px 0 50px 0;
		}


		.wrapper {
			max-width: 960px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
		}

		article {
			width: 100%;

			@include tablet-portrait {
				width: 70%;
			}

			p {
				font-family: $garamond;
				color: $dark-blue;
				font-size: 1.5em;
				line-height: 1.25em;

				& + p,
				& + blockquote {
					padding-top: 50px;
				}

			}

			blockquote {
				margin-left: 0;
				padding: 25px !important;

				p {
					color: $red;
					font-size: 1.75em;
					line-height: 1.5em;
					font-family: $bebas;
					font-style: italic;
				}
				
			}

		}

		aside {
			width: 100%;
			padding: 30px;
			background: $white;
			box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
			margin: 50px 0 0 0;

			@include tablet-portrait {
				width: 25%;
				margin: 0;
			}

			h3 {
				color: $red;
				font-family: $big-noodle;
				letter-spacing: 0.08em;
				font-size: 1.5em;
				padding: 0 0 10px 0;
			}

			p {
				padding: 0 0 50px 0;
				font-size: 0.875em;
				font-weight: 600;

				&:last-of-type {
					padding: 0;
				}
			}

			.social {
				display: flex;
				padding: 0 0 25px 0;

				a {
					max-width: 24px;
					display: block;

					& + a {
						margin: 0 0 0 20px;
					}
				}
			}
		}
	}



}