footer {
	background: $dark-blue;
	padding: 50px 0;

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		& > * {
			width: 100%;
			padding: 0 0 50px 0;

			@include tablet-portrait {
				width: auto;
				padding: 0;
			}
		}

	}

	a.usau-logo {
		max-width: 100px;
	}

	.social {
		display: flex;

		@include tablet-portrait {
			justify-content: center;
		}

		a {
			max-width: 24px;
			margin: 0 40px 0 0;

			@include tablet-portrait {
			margin: 0 20px;

			}
		}
	}


	p {
		font-size: 0.8125em;
		color: $white;
		opacity: 0.6;
		padding: 0 !important;
	}

}