body.home {

	section#hero {

		article {
			@include aspect-ratio(16, 9);
			max-height: 720px;

			.wrapper {
				height: 100%;

				.info {
					display: flex;
					height: 100%;
					justify-content: center;
					align-items: center;

					h2 {
						color: $white;
						font-size: 2em;
						font-family: $big-noodle;
						letter-spacing: 0.15em;
						text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
						text-align: center;

						@include mobile-landscape {
							font-size: 3em;
						}

						@include tablet-portrait {
							font-size: 4em;
						}

						@include desktop {
							font-size: 8em;
						}
					}
				}
			}

		}


	}

	section#features {
		display: flex;
		flex-wrap: wrap;

		.feature {
			width: 100%;
			@include aspect-ratio(16, 9);

			@include tablet-landscape {
				width: 50%;
			}

			.content {
				display: flex;
				padding: 40px;
				align-items: flex-end;

				.info {

					h3 {
						font-size: 2em;
						padding: 0 0 20px 0;
					}

					p {
						padding: 0 0 20px 0;
						line-height: 1.25em;
						max-width: 400px;
					}
				}
			}

			&.blue {
				background-color: $dark-blue;
				color: $white;

				.btn:hover {
					color: $blue;
				}
			}

			&.red {
				background-color: $red;
				color: $white;
			}

			&.background-image {
				.info {
					h3, p {
						text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
					}
				}				
			}

			img.graphic-headline {
				max-width: 150px;
				margin-bottom: 20px;
			}

		}

	}
	
}