/* Media Queries
----------------------------------*/
$mobile-portrait-width: 480px;
$mobile-landscape-width: 568px;
$tablet-portrait-width: 768px;
$tablet-landscape-width: 1024px;
$desktop-width: 1280px;

@mixin mobile-portrait {
	@media screen and (min-width: #{$mobile-portrait-width}) {
		@content;
	}
}

@mixin mobile-landscape {
	@media screen and (min-width: #{$mobile-landscape-width}) {
		@content;
	}
}

@mixin tablet-portrait {
	@media screen and (min-width: #{$tablet-portrait-width}) {
		@content;
	}
}

@mixin tablet-landscape {
	@media screen and (min-width: #{$tablet-landscape-width}) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: #{$desktop-width}) {
		@content;
	}
}


@mixin wrapper-padding {
	margin: 0 auto;
	max-width: 1150px; 
	padding: 0 25px;
	transition: padding 0.2s ease-in;

	@include tablet-landscape {
		padding: 0 50px;
	}

	@media screen and (min-width: 1250px) {
		padding: 0;
	}
}

@mixin aspect-ratio($width, $height) {
	position: relative;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}