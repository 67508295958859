/*

    Theme Name: Live Ultimate
    Theme URI: http://liveultimate.us/
    Author: Andrew Lovseth
    Author URI: http://www.andrewlovseth.com/
    Description: Version 1.0 Live Ultimate from USA Ultimate
    Version: 1.0

*/






/*

    ----------------------------------------------------------------------
                                01 Global
    ----------------------------------------------------------------------

*/

@import "global/variables";
@import "global/mixins";
@import "global/reset";
@import "global/typography";
@import "global/layout";

@import "plugins/slick";
@import "plugins/slick-theme";




/*

    ----------------------------------------------------------------------
                                02 Sections
    ----------------------------------------------------------------------

*/

@import "header";
@import "footer";
@import "page";



/*

    ----------------------------------------------------------------------
                                03 Templates
    ----------------------------------------------------------------------

*/

@import "home";
@import "about";
@import "archive-athletes";
@import "single-athletes";
