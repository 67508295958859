body.page-template-about {

	section#header {
		background: $black;
		color: $white;

		h1 {
			color: $white;
		}
	}

	section#video {
		background: $black;
		padding-bottom: 50px;
	}

	section#story {
		padding: 50px 0;

		@include tablet-landscape {
			padding: 100px 0;
		}

		.wrapper {
			max-width: 640px;
		}

		p {
			font-family: $proxima-nova;
			font-weight: 600;
			color: $dark-blue;
			font-size: 1.5em;
			line-height: 1.25em;

			& + p {
				padding: 50px 0 0 0;
			}

		}
	}


}