/*

    Theme Name: Live Ultimate
    Theme URI: http://liveultimate.us/
    Author: Andrew Lovseth
    Author URI: http://www.andrewlovseth.com/
    Description: Version 1.0 Live Ultimate from USA Ultimate
    Version: 1.0

*/
/*

    ----------------------------------------------------------------------
                                01 Global
    ----------------------------------------------------------------------

*/
/* Colors
----------------------------------*/
/* Typography
----------------------------------*/
@font-face {
  font-family: 'bignoodletitlingregular';
  src: url("fonts/big_noodle_titling-webfont.woff2") format("woff2"), url("fonts/big_noodle_titling-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* Media Queries
----------------------------------*/
/* HTML5 */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

/* HTML & BODY */
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: sans-serif; }

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4; }

/* Links */
a {
  text-decoration: none; }

a:focus {
  outline: none; }

a:hover, a:active {
  outline: none; }

/* Typography */
abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 600; }

i, em {
  font-style: italic; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000; }

p, pre {
  margin: 1em 0; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: normal;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0; }

/* Lists */
dd {
  margin: 0 0 0 40px; }

ul, ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

/* Embedded Content */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

/* Figure */
figure {
  margin: 0; }

/* Forms */
form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/* Tables */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

/* Absolute Positioning */
div, article, section, header, footer, nav, figure, li {
  position: relative; }

/* Selection  */
::-moz-selection {
  background: #BBBBBB;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #BBBBBB;
  color: #fff;
  text-shadow: none; }

/* Clearfix */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

/* Border-Box */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/* Image replacement */
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px; }

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 100%; }

/* Responsive Image */
img {
  display: block;
  max-width: 100%; }

html, input, textarea {
  -webkit-font-smoothing: antialiased; }

body {
  text-rendering: optimizeLegibility; }

body, input, textarea, button {
  color: #2a2a2a;
  font: 400 100%/1 "proxima-nova", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "bebas-neue", sans-serif;
  font-weight: normal; }

a {
  color: #435273;
  transition: all 0.2s ease-in-out; }

.btn {
  display: inline-block;
  background: transparent;
  color: #fff;
  padding: 14px 25px 12px;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-size: 0.8125em;
  letter-spacing: 0.05em;
  font-weight: 600; }
  .btn:hover {
    background: #fff;
    color: #EA1D22; }

body {
  background: #fcfcfc; }

.wrapper {
  margin: 0 auto;
  max-width: 1150px;
  padding: 0 25px;
  transition: padding 0.2s ease-in; }
  @media screen and (min-width: 1024px) {
    .wrapper {
      padding: 0 50px; } }
  @media screen and (min-width: 1250px) {
    .wrapper {
      padding: 0; } }

.cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

section#header {
  padding: 50px 0;
  text-align: center; }
  section#header h1 {
    font-family: "bignoodletitlingregular", sans-serif;
    font-size: 2.5em;
    letter-spacing: 0.1em;
    color: #19243A; }
    @media screen and (min-width: 768px) {
      section#header h1 {
        font-size: 3.5em; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 45px;
  width: 45px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: 1px solid #ddd;
  z-index: 1000;
  outline: none;
  box-shadow: 0 4px 4px rgba(205, 205, 205, 0.5); }
  @media screen and (min-width: 768px) {
    .slick-prev,
    .slick-next {
      border: none; } }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.4; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 0; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 0; }
  @media screen and (min-width: 1280px) {
    .slick-prev {
      left: 0; }
      [dir="rtl"] .slick-prev {
        left: auto;
        right: 0; } }
  .slick-prev:before {
    background: url(images/left-arrow.png) no-repeat 0 0;
    width: 45px;
    height: 45px;
    content: '';
    display: block;
    background-size: 45px 45px; }

.slick-next {
  right: 0; }
  [dir="rtl"] .slick-next {
    left: 0;
    right: auto; }
  @media screen and (min-width: 1280px) {
    .slick-next {
      right: 0; }
      [dir="rtl"] .slick-next {
        left: 0;
        right: auto; } }
  .slick-next:before {
    background: url(images/right-arrow.png) no-repeat 0 0;
    width: 45px;
    height: 45px;
    content: '';
    display: block;
    background-size: 45px 45px; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      width: 12px;
      height: 12px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: ' ';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        background-color: #fff;
        opacity: 0.8;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      opacity: 0.8;
      background: #EA1D22; }

/*

    ----------------------------------------------------------------------
                                02 Sections
    ----------------------------------------------------------------------

*/
header {
  background: #19243A;
  padding: 20px 0; }
  header .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
  header #header-logo {
    display: flex;
    align-items: center; }
    header #header-logo a.usau-logo {
      max-width: 80px;
      padding: 0 20px 0 0; }
      @media screen and (min-width: 768px) {
        header #header-logo a.usau-logo {
          max-width: 120px; } }
    header #header-logo h1 {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      padding: 0 0 0 20px;
      font-size: 2em;
      letter-spacing: 0.05em; }
      @media screen and (min-width: 768px) {
        header #header-logo h1 {
          font-size: 3em; } }
      header #header-logo h1 a {
        line-height: 0.75em; }
        header #header-logo h1 a span.live {
          display: block;
          font-size: 0.75em;
          color: #EA1D22; }
        header #header-logo h1 a span.ultimate {
          display: block;
          color: #fff; }
  header nav {
    display: none;
    width: 100%;
    padding: 20px 0 0 0; }
    @media screen and (min-width: 768px) {
      header nav {
        width: auto;
        display: block !important; } }
    header nav a {
      font-family: "proxima-nova", sans-serif;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.1em;
      font-size: 0.8125em;
      transition: all 0.2s ease-in-out;
      display: block;
      text-align: center;
      padding: 20px 0; }
      header nav a:hover {
        text-decoration: underline; }
      @media screen and (min-width: 768px) {
        header nav a {
          margin-left: 50px;
          border-bottom: 2px solid transparent;
          padding: 0 0 5px 0;
          display: inline-block;
          text-align: left; }
          header nav a:hover {
            border-bottom: 1px solid #fff;
            text-decoration: none; } }
  header #toggle {
    width: 34px;
    cursor: pointer;
    padding: 8px 5px 8px 5px;
    transition: all 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
    margin-right: 0; }
    @media screen and (min-width: 768px) {
      header #toggle {
        display: none; } }
    header #toggle .patty {
      width: 30px;
      height: 3px;
      background: #fff;
      transition: all 0.3s; }
      header #toggle .patty:after, header #toggle .patty:before {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        background: #fff;
        position: absolute;
        z-index: 1;
        transition: all 0.3s; }
      header #toggle .patty:before {
        top: -8px; }
      header #toggle .patty:after {
        top: 8px; }
  header.open #toggle .patty {
    background: none; }
    header.open #toggle .patty:after {
      top: 0;
      transform: rotate(45deg); }
    header.open #toggle .patty:before {
      top: 0;
      transform: rotate(-45deg); }

footer {
  background: #19243A;
  padding: 50px 0; }
  footer .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    footer .wrapper > * {
      width: 100%;
      padding: 0 0 50px 0; }
      @media screen and (min-width: 768px) {
        footer .wrapper > * {
          width: auto;
          padding: 0; } }
  footer a.usau-logo {
    max-width: 100px; }
  footer .social {
    display: flex; }
    @media screen and (min-width: 768px) {
      footer .social {
        justify-content: center; } }
    footer .social a {
      max-width: 24px;
      margin: 0 40px 0 0; }
      @media screen and (min-width: 768px) {
        footer .social a {
          margin: 0 20px; } }
  footer p {
    font-size: 0.8125em;
    color: #fff;
    opacity: 0.6;
    padding: 0 !important; }

body.page-template-default section#main.generic {
  padding: 50px 0; }
  @media screen and (min-width: 768px) {
    body.page-template-default section#main.generic {
      min-height: 600px; } }
  @media screen and (min-width: 1280px) {
    body.page-template-default section#main.generic {
      min-height: 800px; } }
  body.page-template-default section#main.generic h1 {
    font-size: 2em;
    padding: 0 0 20px 0; }

/*

    ----------------------------------------------------------------------
                                03 Templates
    ----------------------------------------------------------------------

*/
body.home section#hero article {
  position: relative;
  max-height: 720px; }
  body.home section#hero article:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%; }
  body.home section#hero article > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  body.home section#hero article .wrapper {
    height: 100%; }
    body.home section#hero article .wrapper .info {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center; }
      body.home section#hero article .wrapper .info h2 {
        color: #fff;
        font-size: 2em;
        font-family: "bignoodletitlingregular", sans-serif;
        letter-spacing: 0.15em;
        text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
        text-align: center; }
        @media screen and (min-width: 568px) {
          body.home section#hero article .wrapper .info h2 {
            font-size: 3em; } }
        @media screen and (min-width: 768px) {
          body.home section#hero article .wrapper .info h2 {
            font-size: 4em; } }
        @media screen and (min-width: 1280px) {
          body.home section#hero article .wrapper .info h2 {
            font-size: 8em; } }

body.home section#features {
  display: flex;
  flex-wrap: wrap; }
  body.home section#features .feature {
    width: 100%;
    position: relative; }
    body.home section#features .feature:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 56.25%; }
    body.home section#features .feature > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @media screen and (min-width: 1024px) {
      body.home section#features .feature {
        width: 50%; } }
    body.home section#features .feature .content {
      display: flex;
      padding: 40px;
      align-items: flex-end; }
      body.home section#features .feature .content .info h3 {
        font-size: 2em;
        padding: 0 0 20px 0; }
      body.home section#features .feature .content .info p {
        padding: 0 0 20px 0;
        line-height: 1.25em;
        max-width: 400px; }
    body.home section#features .feature.blue {
      background-color: #19243A;
      color: #fff; }
      body.home section#features .feature.blue .btn:hover {
        color: #435273; }
    body.home section#features .feature.red {
      background-color: #EA1D22;
      color: #fff; }
    body.home section#features .feature.background-image .info h3, body.home section#features .feature.background-image .info p {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }
    body.home section#features .feature img.graphic-headline {
      max-width: 150px;
      margin-bottom: 20px; }

body.page-template-about section#header {
  background: #000;
  color: #fff; }
  body.page-template-about section#header h1 {
    color: #fff; }

body.page-template-about section#video {
  background: #000;
  padding-bottom: 50px; }

body.page-template-about section#story {
  padding: 50px 0; }
  @media screen and (min-width: 1024px) {
    body.page-template-about section#story {
      padding: 100px 0; } }
  body.page-template-about section#story .wrapper {
    max-width: 640px; }
  body.page-template-about section#story p {
    font-family: "proxima-nova", sans-serif;
    font-weight: 600;
    color: #19243A;
    font-size: 1.5em;
    line-height: 1.25em; }
    body.page-template-about section#story p + p {
      padding: 50px 0 0 0; }

body.post-type-archive-athletes section#list .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

body.post-type-archive-athletes section#list article {
  width: 100%;
  padding: 0 0 50px 0; }
  @media screen and (min-width: 568px) {
    body.post-type-archive-athletes section#list article {
      width: 48.5%; } }
  @media screen and (min-width: 1024px) {
    body.post-type-archive-athletes section#list article {
      width: 32%;
      padding: 0 0 100px 0; } }
  body.post-type-archive-athletes section#list article .info {
    padding: 20px 0 0 0;
    text-align: center; }
    body.post-type-archive-athletes section#list article .info h3 {
      font-family: "bignoodletitlingregular", sans-serif;
      font-size: 1.75em;
      letter-spacing: 0.1em; }
      body.post-type-archive-athletes section#list article .info h3 a {
        color: #19243A; }

body.single-athletes section#hero {
  position: relative;
  max-height: 1080px;
  border: 10px solid #fff; }
  body.single-athletes section#hero:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%; }
  body.single-athletes section#hero > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  body.single-athletes section#hero .wrapper {
    display: flex;
    height: 100%;
    align-items: flex-end; }
    @media screen and (min-width: 768px) {
      body.single-athletes section#hero .wrapper {
        align-items: flex-start; } }
    body.single-athletes section#hero .wrapper .info {
      margin: 0 0 20px 0; }
      @media screen and (min-width: 768px) {
        body.single-athletes section#hero .wrapper .info {
          margin: 20px 0 0 0; } }
  body.single-athletes section#hero h1 {
    font-family: "bignoodletitlingregular", sans-serif;
    font-size: 2em;
    background: #fff;
    display: inline-block;
    padding: 10px 10px 0 10px;
    line-height: 1em;
    color: #435273; }
    @media screen and (min-width: 768px) {
      body.single-athletes section#hero h1 {
        font-size: 3.5em;
        padding: 20px 20px 0 20px; } }

body.single-athletes section#profile {
  padding: 20px 0 50px 0; }
  @media screen and (min-width: 768px) {
    body.single-athletes section#profile {
      padding: 40px 0 50px 0; } }
  body.single-athletes section#profile .wrapper {
    max-width: 960px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
  body.single-athletes section#profile article {
    width: 100%; }
    @media screen and (min-width: 768px) {
      body.single-athletes section#profile article {
        width: 70%; } }
    body.single-athletes section#profile article p {
      font-family: "EB Garamond", serif;
      color: #19243A;
      font-size: 1.5em;
      line-height: 1.25em; }
      body.single-athletes section#profile article p + p,
      body.single-athletes section#profile article p + blockquote {
        padding-top: 50px; }
    body.single-athletes section#profile article blockquote {
      margin-left: 0;
      padding: 25px !important; }
      body.single-athletes section#profile article blockquote p {
        color: #EA1D22;
        font-size: 1.75em;
        line-height: 1.5em;
        font-family: "bebas-neue", sans-serif;
        font-style: italic; }
  body.single-athletes section#profile aside {
    width: 100%;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
    margin: 50px 0 0 0; }
    @media screen and (min-width: 768px) {
      body.single-athletes section#profile aside {
        width: 25%;
        margin: 0; } }
    body.single-athletes section#profile aside h3 {
      color: #EA1D22;
      font-family: "bignoodletitlingregular", sans-serif;
      letter-spacing: 0.08em;
      font-size: 1.5em;
      padding: 0 0 10px 0; }
    body.single-athletes section#profile aside p {
      padding: 0 0 50px 0;
      font-size: 0.875em;
      font-weight: 600; }
      body.single-athletes section#profile aside p:last-of-type {
        padding: 0; }
    body.single-athletes section#profile aside .social {
      display: flex;
      padding: 0 0 25px 0; }
      body.single-athletes section#profile aside .social a {
        max-width: 24px;
        display: block; }
        body.single-athletes section#profile aside .social a + a {
          margin: 0 0 0 20px; }
